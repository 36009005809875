@import "bootstrap/scss/bootstrap";

.nav-margin {
	margin-top: 15px;
}

.nav-link.active {
	background: blue !important;
	color: white !important;
	font-weight: bold !important;
}

.items-header {
	display: flex;
	width: 100%;
	justify-content: between;
	padding-left: 8px;
	padding-right: 8px;
	padding-bottom: 4px;
}

.items-title {
	display: inline-block;
	align-self: flex-end;
}

.items-title-label {
	margin-bottom: 0;
}

.items-pane {
	width: 100%;
	height: calc(100vh - 185px);
	background: #efefef;
	border: 2px #666666 solid;
	border-style: inset;
	padding: 6px;
	overflow-y: auto;
}

.list-item:hover {
	cursor: pointer;
	color: white;
	background: #4285F4;
}

.list-item:hover a {
	color: white;
	text-decoration: none;
}

.list-item button {
	color: white;
	background: #000;
}

.list-item button:hover {
	color: white;
}

.card-form {
	width: 600px !important;
}

// css markup for custom file input control
.custom-file-input{
	display: inline-block;
	overflow: hidden;
	position: relative;
	opacity: 1 !important;
//	height: calc(1.5em + 0.75rem + 2px) !important;
	height: calc(2.5em) !important;
}


.custom-file-input input[type="file"] {
	width: 100%;
	height: 100%;
	opacity: 0;
	filter: alpha(opacity=0);
	zoom: 1;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
}

.custom-file-input input[type="text"] {
	width: 68%;
	height: 100%;
	padding-left: 3px;
}

.custom-file-input input[type="button"] {
	display: inline-block;
	margin-bottom: 5px;
}

.modal-action-pane {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
}

.modal-action-button {
	display: inline-block;
	margin-bottom: 10px;
}

.modal-file-field {
	margin-top: -22px; 
	margin-bottom: 24px; 
	font-size: 12.5pt;
}

.modal-content-card {
	padding: 2px !important;
}

.collection-overlay {
	margin: 0 !important;
}

.row-padding {
	margin-top: 30px !important;
}

.card-content-title {
	padding: 12px !important;
}

.test {
	color:white;
}




